import React from "react";
import { useEffect, useState } from "react";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { BaseUrl } from "../Constant/BaseUrl";
import TermsAndModal from "../components/TermsAndModal";
import PrivacyModal from "../components/PrivacyModal";
import MessageModal from "../components/MessageModal";

function EmcRegistration() {
  const [formData, setFormData] = useState({
    employerName: "",
    noOfEmployee: "",
    email: "",
    phone: "",
    state: "",
    district: "",
    pinCode: "",
    typeOfCompany: "",
  });

  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [companyTypeData, setCompanyTypeData] = useState([]);
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  const [apiMessage, setApiMessage] = useState({
    type: "",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else if (name === "noOfEmployee") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else if (name === "pinCode") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // Submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BaseUrl}submit-enquiry`,
        JSON.stringify(formData),
        { headers: { "Content-Type": "application/json" } },
      );

      if (response.data.status) {
        openModal();
        resetForm();
      } else {
        displayApiMessage("error_message", response.data.msg);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg ||
        "An error occurred while submitting the form.";
      displayApiMessage("error_message", errorMessage);
    }
  };

  const resetForm = () => {
    setFormData({
      employerName: "",
      noOfEmployee: "",
      email: "",
      phone: "",
      state: "",
      district: "",
      pinCode: "",
      typeOfCompany: "",
    });
    setDistrictData([]);
  };

  const displayApiMessage = (type, message) => {
    setApiMessage({ type, message });
    setTimeout(() => setApiMessage({ type: "", message: "" }), 2000);
  };

  // Fetch state list
  const fetchStateData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}state`);
      setStateData(response.data.data || []);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch states.");
    }
  };

  // Fetch district list based on state selection
  const fetchDistrictData = async (state_id) => {
    if (!state_id) return setDistrictData([]);
    try {
      const response = await axios.post(
        `${BaseUrl}district`,
        JSON.stringify({ state_id }),
        { headers: { "Content-Type": "application/json" } },
      );
      setDistrictData(response.data.data || []);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch districts.");
    }
  };

  const getCompanyTypeList = async () => {
    try {
      const response = await axios.get(`${BaseUrl}get-company-type`);
      setCompanyTypeData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchStateData();
    getCompanyTypeList();
  }, []);

  useEffect(() => {
    fetchDistrictData(formData.state);
  }, [formData.state]);

  return (
    <WebLayout>
      <main id="top_bottom">
        <section
          className="breadcrumb__area breadcrumb__bg"
          style={{ padding: 20 }}
        >
          <div className="container">
            <div className="row">
              <TermsAndModal
                termsModal={termsModal}
                setTermsModal={setTermsModal}
              />
              <PrivacyModal
                privacyModal={privacyModal}
                setPrivacyModal={setPrivacyModal}
              />
              <div
                className={"col-lg-12 mb-3"}
                style={{ background: "#f7a400" }}
              >
                <h1
                  className="title text-center m-0"
                  style={{ fontStyle: "italic", fontSize: 35 }}
                >
                  ENTITY MEMBER COMPANY (EMC)
                </h1>
              </div>
              <div className="col-lg-6">
                <p style={{ fontSize: 20 }}>
                  <span
                    style={{
                      color: "#f7a400",
                      fontWeight: 800,
                    }}
                  >
                    Any legal company / institutions / organizations are
                    eligible to be registered as Entity Member Company (EMC) to
                    get the services offered by the Abhhyam Secured Life (ASL).
                  </span>
                </p>
                <ul style={{ listStyle: "decimal" }}>
                  <li>
                    No registration fees, can submit request proposal at&nbsp;
                    <Link to={"/"}>www.AbhhyamSecure.com</Link> for process.
                  </li>
                  <li>
                    On the first invoice of the HRM services contract from ASL,
                    the ASL ID Account number will be allocated for each number
                    of employee account created by the Entity Member Company
                    (EMC).
                  </li>
                  <li>
                    The Entity Member Company (EMC) has to declare its total
                    number of employees at the time of registration to create an
                    equal number of ASL ID Accounts for each employee to
                    facilitate Pensionary and other services benefits.
                  </li>
                  <li>All process is digitally designed.</li>
                </ul>
              </div>
              <div className="col-lg-6">
                {apiMessage.message && (
                  <p className={`${apiMessage.type}`}>{apiMessage.message}</p>
                )}
                <div
                  className="contact__form-wrap"
                  style={{ margin: 0, background: "#f7a400" }}
                >
                  <form id="registrationForm" onSubmit={handleSubmit}>
                    <div className="row">
                      <InputField
                        name="employerName"
                        value={formData.employerName}
                        handleChange={handleInputChange}
                        placeholder="Employer Name"
                        required
                      />
                      <InputField
                        name="noOfEmployee"
                        value={formData.noOfEmployee}
                        handleChange={handleInputChange}
                        placeholder="No. of Employee"
                        type="noOfEmployee"
                        required
                      />
                      <InputField
                        name="email"
                        value={formData.email}
                        handleChange={handleInputChange}
                        placeholder="Email"
                        required
                      />
                      <InputField
                        name="phone"
                        value={formData.phone}
                        handleChange={handleInputChange}
                        placeholder="Phone"
                        type="phone"
                        maxLength={10}
                        minLength={10}
                        required
                      />

                      <SelectField
                        name="state"
                        value={formData.state}
                        handleChange={handleInputChange}
                        options={stateData}
                        placeholder="Select State"
                        required
                      />
                      <SelectField
                        name="district"
                        value={formData.district}
                        handleChange={handleInputChange}
                        options={districtData}
                        placeholder="Select District"
                        required
                      />
                      <InputField
                        name="pinCode"
                        value={formData.pinCode}
                        handleChange={handleInputChange}
                        placeholder="Pincode"
                        maxLength={6}
                        required
                      />
                      <SelectField
                        name="typeOfCompany"
                        value={formData.typeOfCompany}
                        handleChange={handleInputChange}
                        options={companyTypeData}
                        placeholder="Select Type of Company"
                        required
                        column={"col-md-6"}
                      />
                    </div>
                    <div className="form-grp checkbox-grp">
                      <input
                        type="checkbox"
                        id="termsCheckbox"
                        checked
                        readOnly
                      />
                      <label style={{ fontWeight: "bold" }}>
                        I accept{" "}
                        <Link
                          to={"#"}
                          onClick={() => setTermsModal(true)}
                          style={{ fontWeight: "bold", color: "#3913ff" }}
                        >
                          Terms & Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          to={"#"}
                          onClick={() => setPrivacyModal(true)}
                          style={{ fontWeight: "bold", color: "#3913ff" }}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                    <div className="submit-button text-center">
                      <button
                        type="submit"
                        className="btn-success"
                        style={{
                          background: "#fb4343",
                          width: "100%",
                          color: "#ffffff",
                          padding: 10,
                          borderRadius: 10,
                          borderWidth: 0,
                          fontWeight: "bold",
                        }}
                      >
                        REGISTER
                      </button>
                      <p className={"m-2"}>
                        <b>OR</b>
                      </p>
                      <Link
                        to={"https://emc.abhhyamsecure.com/"}
                        target={"_blank"}
                      >
                        <button
                          type="button"
                          className="btn-success"
                          style={{
                            background: "#5bbf69",
                            width: "100%",
                            color: "#ffffff",
                            padding: 10,
                            borderRadius: 10,
                            borderWidth: 0,
                            fontWeight: "bold",
                          }}
                        >
                          LOGIN
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <MessageModal
            isOpen={isModalOpen}
            onClose={closeModal}
            message={
              "Your enquiry has been successfully submitted. Thank you for reaching out to us! We are reviewing your enquiry and will respond to you shortly."
            }
          />
        </section>
      </main>
    </WebLayout>
  );
}

function InputField({
  type,
  name,
  value,
  handleChange,
  placeholder,
  error,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle the password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <div className="col-md-6">
      <div className="form-grp" style={{ position: "relative" }}>
        <input
          type={
            type === "password" && !showPassword
              ? "password"
              : type === "email"
                ? "email"
                : "text"
          }
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
          style={{
            background: "#ffd788",
            border: error ? "2px solid red" : "",
            fontWeight: 500,
            paddingRight: "30px", // Space for the eye icon
          }}
          className={"textPlaceholder"}
        />
        {type === "password" && (
          <div
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            {showPassword ? (
              <i className={"fa fa-eye-slash"} />
            ) : (
              <i className={"fa fa-eye"} />
            )}
          </div>
        )}
        {error && <div className="text-danger">{error}</div>}
      </div>
    </div>
  );
}

function SelectField({
  name,
  value,
  handleChange,
  options,
  placeholder,
  column = "col-md-6",
}) {
  return (
    <div className={column}>
      <div className="form-grp">
        <select
          name={name}
          value={value}
          onChange={handleChange}
          style={{
            background: "#ffd788",
          }}
        >
          <option value="">{placeholder}</option>
          {options.map((opt, i) => (
            <option key={i} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default EmcRegistration;
