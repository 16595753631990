import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function Initiative() {
  return (
    <WebLayout>
      <>
        <Link
          to="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html"
        >
          <i className="fas fa-angle-up"></i>
        </Link>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Initiative</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Initiative
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src={"./assets/img/images/breadcrumb_shape01.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape02.png"}
                alt=""
                className="rightToLeft"
              />
              <img src={"./assets/img/images/breadcrumb_shape03.png"} alt="" />
              <img src={"./assets/img/images/breadcrumb_shape04.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape05.png"}
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section className="services__details-area">
            <div className="container">
              <div className="services__details-wrap">
                <div className="row">
                  <div className="col-70 order-0 order-lg-2">
                    <div className="services__details-top">
                      <div className="row gutter-24 align-items-center">
                        <div className="col-49">
                          <div className="services__details-thumb services__details-thumb-two">
                            <img
                              src={
                                "./assets/img/marketing_plan/initiative/1.jpg"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-51">
                          <div className="services__details-top-content">
                            <h2 className="title">PARA-1: Concept Note</h2>
                            <p>
                              Employee welfare and care is a cornerstone of
                              organizational success. When organizations invest
                              in the well-being of their employees, they create
                              a workplace where employees are satisfied,
                              motivated, and committed to achieving the
                              organization's goals. Employee welfare is not just
                              an ethical obligation; it is a strategic advantage
                              that positively impacts an organization's
                              performance, culture, and reputation.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="services__details-content">
                      <p>
                        As organizations continue to evolve, they must recognize
                        that the heart of their success lies in nurturing the
                        well-being of their employees. By investing in
                        employees' well-being and providing the necessary
                        support, organizations can create a positive and
                        productive work environment, improve job satisfaction,
                        and ultimately achieve better business results. Employee
                        care is a win-win, benefiting both employees and the
                        organization. If employee happy, the company is happy,
                        if employee secure, the company secure or we can say If
                        citizen is secured, the country will be secured.
                      </p>
                      <div className="services__details-list-two mb-2">
                        <div className="row gutter-24">
                          <div className="col-md-6">
                            <div className="services__details-list-box-two">
                              <div className="icon">
                                <img
                                  src={
                                    "./assets/img/marketing_plan/initiative/2.png"
                                  }
                                  alt={""}
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">PARA-2: Vision</h4>
                                <p>
                                  Fostering a workplace culture dedicated to
                                  securing the life of every employee, where
                                  holistic well-being, professional growth, and
                                  comprehensive benefits converge to create a
                                  thriving and secure future for all.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="services__details-list-box-two">
                              <div className="icon">
                                <img
                                  src={
                                    "./assets/img/marketing_plan/initiative/3.png"
                                  }
                                  alt={""}
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">PARA-3: Mission</h4>
                                <p>
                                  “Ensuring the well-being and safety of every
                                  employee, fostering a secure and thriving work
                                  environment."
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="services__details-inner-two">
                        <div className="row gutter-24 align-items-center">
                          <div className="col-100 order-0 order-md-0">
                            <div className="services__details-inner-img">
                              <img
                                src={
                                  "./assets/img/marketing_plan/initiative/4.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-100">
                            <div className="services__details-inner-content-two">
                              <h4 className="title mt-2 mb-2">
                                PARA-4: Employment needs of the day
                              </h4>
                              <p>
                                The employment landscape in India presents a set
                                of challenges and opportunities. While
                                unemployment rates have been a concern, the
                                Government and stakeholders have taken several
                                initiatives to address the issue. Skill
                                development programs like Skill India and{" "}
                                <b>PMKVY</b>
                                aim to bridge the skill gap and enhance
                                employability. Startup and entrepreneurship
                                support initiatives foster innovation and job
                                creation. Digital transformation through the
                                Digital India campaign opens up new avenues for
                                employment. Collaboration between academia and
                                industry helps align education with industry
                                needs. Infrastructure development projects
                                generate employment opportunities. Rural
                                employment programs like <b>MGNREGA</b> provide
                                livelihood support. International Collaborations
                                promote job placements abroad. Women empowerment
                                schemes promote women’s participation in the
                                workforce. Reskilling and upskilling programs
                                address the impact of technology. However,
                                sustained efforts and Collaboration among
                                stakeholders are necessary to overcome these
                                challenges and create a robust employment
                                ecosystem in India. There is a huge need to take
                                various initiative to develop the employment
                                opportunities with secure life of employees.
                              </p>
                            </div>
                          </div>
                          <div className="col-100 order-0 order-md-0 mt-2">
                            <div className="services__details-inner-img">
                              <img
                                src={
                                  "./assets/img/marketing_plan/initiative/5.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2 className="title-two">
                        PARA-5: HRM and placement sector india
                      </h2>
                      <div className="col-100 order-0 order-md-0 mb-2">
                        <div className="services__details-inner-img">
                          <img
                            src={"./assets/img/marketing_plan/initiative/6.png"}
                            alt=""
                          />
                        </div>
                      </div>
                      <p>
                        The Indian government has played a very important role
                        in shaping the placement sector. initiatives like "Make
                        in India" and "Startup India" have spurred job creation
                        in manufacturing and the startup ecosystem.
                        Additionally, schemes like the National Career Service
                        (NCS) and the Skill India mission aim to provide
                        job-related services, career counselling, and skill
                        development to job seekers.
                      </p>
                      <p>
                        The placement sector in India has seen tremendous growth
                        and innovation, it sll faces challenges. One of the
                        primary challenges is the need for continuous upskilling
                        and reskilling of the workforce to meet the demands of a
                        rapidly evolving job market. Additionally, ensuring
                        inclusivity and addressing unemployment in rural and
                        underserved areas remains a priority.
                      </p>
                      <p>
                        The placement sector in India is undergoing a
                        transformation driven by technological advancements,
                        changing work models, and government initiatives. It has
                        become more dynamic, diverse, and inclusive, connecting
                        job seekers with a wide range of employment
                        opportunities. As the sector continues to evolve, it
                        will play a crucial role in shaping India's workforce
                        and economic future.
                      </p>
                      <p>
                        In our unwavering commitment to excellence, we go beyond
                        traditional service delivery to provide the
                        best-in-class solutions that prioritize both the
                        well-being of employees and the care of employers. Our
                        comprehensive suite of services is designed not only to
                        meet professional needs but to safeguard the holistic
                        health and security of every employee. We recognize that
                        a thriving workforce is the backbone of a successful
                        organization, and as such, we integrate innovative
                        approaches that ensure employees secure life plans,
                        satisfaction, personal growth, and work-life balance.
                        Simultaneously, we understand the vital role employers
                        play in this equation. Our services extend to assisting
                        employers in creating supportive, nurturing work
                        environments, promoting a culture of empathy, and
                        implementing measures that enhance the overall welfare
                        of their team. Through this holistic approach, we aspire
                        to set a new standard for service excellence—one that
                        seamlessly integrates the well-being of both employees
                        and employers, ultimately fostering a harmonious and
                        prosperous workplace
                      </p>
                      <div className="services__details-top">
                        <div className="row gutter-24 align-items-center">
                          <h2 className="title">
                            PARA-6: Initiative: citizen secure - country secure
                          </h2>
                          <div className="col-49">
                            <div className="services__details-thumb services__details-thumb-two">
                              <img
                                src={
                                  "./assets/img/marketing_plan/initiative/7.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-51">
                            <div className="services__details-top-content">
                              <p>
                                Ensuring the security of citizens requires a
                                holistic approach that addresses their basic
                                needs across multiple dimensions. Governments
                                and various organizations must work together to
                                provide physical safety, economic security,
                                social safety nets, food security, shelter,
                                healthcare, mental health support, disaster
                                preparedness, employment opportunities, and
                                access to information. By meeting these
                                fundamental needs, we create a society where
                                citizens can thrive, contribute, and feel
                                secure, ultimately leading to a more stable and
                                prosperous life of citizen as well as nation.
                              </p>
                              <p>
                                The Aim of Abhhyam Secure Life (ASL) is to
                                support the Government and facilitate each and
                                every organization to take initiative and steps
                                to secure the employee. Together, we can extend
                                all possible resources to the employee to Life a
                                happy and secure life.
                              </p>
                              <p>
                                The Human Resources management services by
                                Abhhyam Secure Life (ASL) will provide you with
                                all the possible support to achieve the goal of
                                a secure life of employees.
                              </p>
                              <p>
                                To secure the life and future of each employee
                                and citizen, Abhhyam Secure Private Limited
                                (ASPL) planned to offer various pensionary
                                benefits on completion of minimum 96 months of
                                tenure through the employer. A secure life offer
                                with lifetime pension bond and other pensionary
                                benefits will help the employer to retain their
                                employees to serve at least 96 months of tenure.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="services__details-top">
                        <div className="row gutter-24 align-items-center">
                          <h2 className="title">
                            PARA-7: Pensionary & Other Service Benefits For
                            Employees:
                          </h2>
                          <div className="col-49">
                            <div className="services__details-thumb services__details-thumb-two">
                              <img
                                src={
                                  "./assets/img/marketing_plan/initiative/8.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-51">
                            <div className="services__details-top-content">
                              <p>
                                Abhhyam Secure Life (ASL) offers pensionary and
                                other benefits for employees through employer,
                                as Pension Bond provide a reliable passive
                                income source during the lifetime, ensuring
                                financial security and preventing dependency on
                                the society of the citizens/employee.
                              </p>
                              <p>
                                Pension bond and other service benefits offers
                                peace of mind promotes loyalty, and contributes
                                to the overall well-being and dignity of
                                employees in their life. Therefore, lifetime
                                pension bonds and other benefits will be able to
                                retain an employee for a longer duration in the
                                office as well as on duty and a peaceful
                                retirement for employees to make a comfortable
                                life with dedication towards the organization.
                              </p>
                            </div>
                          </div>
                          <div className={"col-100"}>
                            <p>
                              Pensionary and other benefits fund for each
                              employee will help to employer (herein will called
                              now Entity Member Company (EMC)) to retain the
                              talent for a period of minimum 96 months of
                              service, however, employee can serve till 65 years
                              of age is permissible as per guidelines of the
                              organizations. Pensionary and other benefits for
                              employees correlated to the services taken by the
                              Entity Member Company (EMC). Every successful
                              services transaction for each employee will
                              generate PV. The invoices will be generated for
                              each service as per the Government norms. On each
                              service, Entity Member Company (EMC) is eligible
                              for PV during a service of employee. These PV will
                              be utilized for pensionary and other benefits for
                              employer and employees.
                            </p>
                            <p>
                              To secure the life and future of each employee and
                              citizen, Abhhyam Secure Private Limited (ASPL)
                              planned to offer various pensionary benefits on
                              completion of minimum 96 months of tenure through
                              the employer. A secure life offer with lifetime
                              pension bond and other pensionary benefits will
                              help the employer to retain their employees to
                              serve at least 96 months of tenure.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secured Life </p>
                          <a
                            href={
                              "./assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            <i className="far fa-file-pdf"></i>PDF Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default Initiative;
