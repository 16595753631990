import React, { useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

const PopupComponent = ({ isOpen, setIsOpen }) => {
  const popupRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("mobile-menu-visible");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("mobile-menu-visible");
    };
  }, [isOpen, handleClickOutside]);

  return (
    <div>
      {isOpen && (
        <div ref={popupRef}>
          <div className={`offCanvas__info active`}>
            <div
              className="offCanvas__close-icon menu-close"
              onClick={() => setIsOpen(false)}
            >
              <button>
                <i className="far fa-window-close"></i>
              </button>
            </div>
            <div className="offCanvas__logo mb-30">
              <Link to="#">
                <img src={"./assets/img/logo/logo.png"} alt="Logo" />
              </Link>
            </div>
            <div className="offCanvas__side-info mb-30">
              <div className="contact-list mb-30">
                <h4>Phone Number</h4>
                <p>+91-9971713967</p>
              </div>
              <div className="contact-list mb-30">
                <h4>Email Address</h4>
                <p>care@abhhyamsecure.com</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupComponent;
