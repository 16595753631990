import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function TermsUse() {
  return (
    <WebLayout>
      <>
        <Link
          to="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html"
        >
          <i className="fas fa-angle-up"></i>
        </Link>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Terms of Use</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Terms of Use
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src={"./assets/img/images/breadcrumb_shape01.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape02.png"}
                alt=""
                className="rightToLeft"
              />
              <img src={"./assets/img/images/breadcrumb_shape03.png"} alt="" />
              <img src={"./assets/img/images/breadcrumb_shape04.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape05.png"}
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section className="about__area-four">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-12">
                  <div className="">
                    <div className="section-title mb-10">
                      <h4>Terms of Use for www.AbhhyamSecure.com</h4>
                    </div>
                    <p>
                      Welcome to www.AbhhyamSecure.com ("Website"), owned and
                      operated by Abhhyam Secure Private Limited ("we," "our,"
                      "us"). By accessing or using this Website, you agree to
                      comply with and be bound by these Terms of Use ("Terms").
                      If you do not agree to these Terms, please do not use this
                      Website.
                    </p>
                    <ul className="listStyleDecimal">
                      <li>
                        <div className="section-title">
                          <h5>Acceptance of Terms</h5>
                        </div>
                        <div className="about__list-box">
                          <p>By using the Website, you confirm that you:</p>
                          <ul className="listStyleDisc">
                            <li>Are at least 18 years old.</li>
                            <li>
                              Have the legal capacity to enter into these Terms.
                            </li>
                            <li>
                              Agree to abide by all applicable laws and
                              regulations.
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="section-title mt-1">
                          <h5>Website Use</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          <p className={"mb-0"}>
                            You agree to use the Website only for lawful
                            purposes and in a manner that does not infringe the
                            rights of others or restrict their use and enjoyment
                            of the Website. Prohibited activities include:
                          </p>
                          <ul className="listStyleDisc mb-0">
                            <li>
                              Unauthorized access to the Website or its systems.
                            </li>
                            <li>Using the Website for fraudulent purposes.</li>
                            <li>
                              Uploading malicious software, viruses, or harmful
                              content.
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Registration and Accounts</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          <ul className="listStyleDisc mb-0">
                            <li>
                              To access certain features, you may be required to
                              register and create an account. You are
                              responsible for maintaining the confidentiality of
                              your account credentials and all activities under
                              your account.
                            </li>
                            <li>
                              You must provide accurate, complete, and current
                              information during registration.
                            </li>
                            <li>
                              We reserve the right to suspend or terminate your
                              account if you violate these Terms.
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Intellectual Property</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          <ul className="listStyleDisc">
                            <li>
                              All content on the Website, including text,
                              graphics, logos, and software, is the property of
                              Abhhyam Secure Private Limited or its licensors
                              and is protected by applicable intellectual
                              property laws.
                            </li>
                            <li>
                              You may not reproduce, distribute, modify, or use
                              any content without prior written permission.
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Services and Transactions</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          <ul className="listStyleDisc mb-0">
                            <li>
                              The Website provides information about the Abhhyam
                              Secured Life (ASL) plan and allows Entity Member
                              Companies (EMCs), Entity Member Accounts (EMAs),
                              and Entity Representative Members (ERMs) to access
                              related services.
                            </li>
                            <li>
                              All transactions are subject to compliance with
                              ASL policies and applicable laws.
                            </li>
                            <li>
                              We reserve the right to modify, suspend, or
                              discontinue any services without notice.
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Limitation of Liability</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          <ul className="listStyleDisc">
                            <li>
                              We strive to ensure the Website operates smoothly
                              and accurately, but we do not guarantee
                              uninterrupted or error-free access.
                            </li>
                            <li>
                              Abhhyam Secured Private Limited is not liable for
                              any direct, indirect, incidental, or consequential
                              damages arising from the use or inability to use
                              the Website or its services.
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>User Responsibilities</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          <ul className="listStyleDisc">
                            <li>
                              You are responsible for ensuring that your use of
                              the Website complies with all applicable laws.
                            </li>
                            <li>
                              You must not misuse the Website, including
                              attempting to gain unauthorized access or
                              transmitting harmful content.
                            </li>
                            <li>
                              You agree to indemnify us against any claims,
                              damages, or liabilities arising from your use of
                              the Website.
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Privacy Policy</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          Your use of the Website is also governed by our
                          Privacy Policy, which explains how we collect, use,
                          and protect your personal information.
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Termination</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          We may suspend or terminate your access to the Website
                          at our sole discretion, without prior notice, if you
                          violate these Terms or engage in activities that harm
                          our interests.
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Changes to Terms</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          We reserve the right to modify these Terms at any
                          time. Changes will be effective immediately upon
                          posting. Continued use of the Website constitutes
                          acceptance of the updated Terms.
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Governing Law</h5>
                        </div>
                        <div className="about__list-box mb-2">
                          These Terms are governed by the laws of India. Any
                          disputes arising from these Terms or the use of the
                          Website will be subject to the exclusive jurisdiction
                          of the courts in Delhi, India.
                        </div>
                      </li>
                      <li>
                        <div className="section-title">
                          <h5>Contact Information</h5>
                        </div>
                        <div className="about__list-box">
                          <p>
                            If you have any questions or concerns about this
                            Privacy Policy or our data practices, please contact
                            us:
                          </p>
                          <ul className="listStyleDisc">
                            <li>
                              <b>Email:</b> care@abhhyamsecure.com
                            </li>
                            <li>
                              <b>Phone:</b> +91-9971 713 967
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <p>
                      By using www.AbhhyamSecure.com, you acknowledge that you
                      have read, understood, and agreed to these Terms of Use.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default TermsUse;
