import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function Incentives() {
  return (
    <WebLayout>
      <>
        <Link
          to="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html"
        >
          <i className="fas fa-angle-up"></i>
        </Link>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Incentives</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Incentives
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src={"./assets/img/images/breadcrumb_shape01.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape02.png"}
                alt=""
                className="rightToLeft"
              />
              <img src={"./assets/img/images/breadcrumb_shape03.png"} alt="" />
              <img src={"./assets/img/images/breadcrumb_shape04.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape05.png"}
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section className="services__details-area">
            <div className="container">
              <div className="services__details-wrap">
                <div className="row">
                  <div className="col-70 order-0 order-lg-2">
                    <div className="services__details-top">
                      <div className="row gutter-24 align-items-center">
                        <h2 className="title">
                          PARA-14: ASL incentives and bonus and funds
                        </h2>
                        <div className="col-100">
                          <div className="services__details-thumb services__details-thumb-two mb-2">
                            <img
                              src={
                                "./assets/img/marketing_plan/incentives/1.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-100">
                          <div className="services__details-top-content">
                            <p>
                              The ASL offers HRM and third party pay rolls
                              services with other support and guidance to the
                              Entity and a reasonable Service Charge will be
                              taken from the Entity. On the basis of the
                              services charge, Point Volume (PV) will be
                              allocated to the Entity against each employee ASL
                              Account.
                            </p>
                            <p>
                              On the closure of the month, monthly fund will be
                              generated from the accumulated PV for each
                              employee and disbursed for each type of proposed
                              incentives, pensionary and services benefits as
                              under:
                            </p>
                            <div className=" about__list-box-three">
                              <ul style={{ listStyle: "lower-alpha" }}>
                                <li>
                                  <b>Entity Member Fund (EMF)</b>
                                  <ul style={{ listStyle: "lower-roman" }}>
                                    <li>EEI: Entity Employee Incentive</li>
                                    <li>EPI: Employee Protection Incentive</li>
                                  </ul>
                                </li>
                                <li>
                                  <b>Abhhyam Secured Life Fund (SLF)</b>
                                  <ul style={{ listStyle: "lower-roman" }}>
                                    <li>SLB: Secure Life Bond</li>
                                    <li>SGF: Secure Gratuity Fund</li>
                                    <li>LDB: Leave Due Bonus</li>
                                    <li>LTF: Leave Travel Fund</li>
                                    <li>WES: Ward Education Scholarship</li>
                                    <li>FSB: Family Secure Bond</li>
                                  </ul>
                                </li>
                                <li>
                                  <b>Entity Rep Member Incentives (ERM)</b>
                                  <ul style={{ listStyle: "lower-roman" }}>
                                    <li>RMB: Rep Member Bonus</li>
                                    <li>PBF: Pension Bond Fund</li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="services__details-content">
                      <h2 className="title mb-2">
                        PARA-15: Entity Member Fund (EMF)
                      </h2>
                      <p>
                        Abhhyam Secured Life (ASL) entitles its EMC to an Entity
                        Member Fund (EMF) equivalent to 40 percent of the total
                        TPP (PV turnover of the month) in a certain month. ASL
                        will distribute this Entity Member Fund to its EMC up to
                        the 20th generation among all EMCs as per self PV
                        generated in a certain month. The two part of Entity
                        Member Fund (EMF) are below-mentioned.
                      </p>
                    </div>
                    <div className="services__details-content">
                      <h2 className="title mb-2">
                        PARA-16: EEI: Entity Employee Incentive 32% of TPP
                      </h2>
                      <p>
                        Abhhyam Secured Life (ASL) entitles its EMC to an Entity
                        Employee Incentive (EEI) equivalent to 32 percent of the
                        total TPP (PV turnover of the month) in a certain month.
                        ASL will distribute EEI up to the 20th generation among
                        all EMCs as per self PV generated in a certain month.
                        EEI is calculated as per the below-mentioned formula:
                      </p>
                      <table className="table table-bordered">
                        <thead className="text-center table-danger">
                          <tr>
                            <th colSpan="3">
                              Entity Employee Incentive (EEI) - 32%
                            </th>
                          </tr>
                          <tr>
                            <th colSpan="3">
                              Example Calculation EEI 32% of TPP
                            </th>
                          </tr>
                          <tr>
                            <th colSpan="3">
                              1st EEI Example Calculation for 4th Gen ID Account
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Team Proportionate PV</th>
                            <td>TPP</td>
                            <td>1,00,00,000.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Generation PV</th>
                            <td>GPV</td>
                            <td> 8,00,000.00</td>
                          </tr>
                          <tr>
                            <th>Individual ID Account PV</th>
                            <td>IPV</td>
                            <td>200.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Secure Life Percentage</th>
                            <td>SLP</td>
                            <td>32.00</td>
                          </tr>
                          <tr>
                            <th>Generation Incentive Percentage</th>
                            <td>GIP</td>
                            <td>8.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Step - 1 = TTE (Total Team Earing)</th>
                            <td>TTE = TPP * SLP% * 5</td>
                            <td> 1,60,00,000.00</td>
                          </tr>
                          <tr>
                            <th>Step – 2 = TGE (Total Generation Earing)</th>
                            <td>TGE = TTE * GIP%</td>
                            <td>12,80,000.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Step - 3 = PVV (Point Volume Value)</th>
                            <td>PVV = TGE / GPV</td>
                            <td>₹ 1.60</td>
                          </tr>
                          <tr>
                            <th>Step - 4 = IDE (ID Account Earning)</th>
                            <td>IDE = PVV * IPV</td>
                            <td>₹ 320.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>
                              Rs.320/- EEI for this level ID Account holder{" "}
                            </th>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table table-bordered">
                        <thead className="text-center table-danger">
                          <tr>
                            <th colSpan="3">
                              2nd EEI Example Calculation for 10th Gen ID
                              Account
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Team Proportionate PV</th>
                            <td>TPP</td>
                            <td>2,00,00,000.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Generation PV</th>
                            <td>GPV</td>
                            <td> 70,000.00</td>
                          </tr>
                          <tr>
                            <th>Individual ID Account PV</th>
                            <td>IPV</td>
                            <td>200.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Secure Life Percentage</th>
                            <td>SLP</td>
                            <td>32.00</td>
                          </tr>
                          <tr>
                            <th>Generation Incentive Percentage</th>
                            <td>GIP</td>
                            <td>8.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Step - 1 = TTE (Total Team Earing)</th>
                            <td>TTE = TPP * SLP% * 5</td>
                            <td> 3,20,00,000.00</td>
                          </tr>
                          <tr>
                            <th>Step – 2 = TGE (Total Generation Earing)</th>
                            <td>TGE = TTE * GIP%</td>
                            <td>25,60,000.00</td>
                          </tr>
                          <tr className="table-success">
                            <th>Step - 3 = PVV (Point Volume Value)</th>
                            <td>PVV = TGE / GPV</td>
                            <td>₹ 36.57</td>
                          </tr>
                          <tr>
                            <th>Step - 4 = IDE (ID Account Earning)</th>
                            <td>IDE = PVV * IPV</td>
                            <td>₹ 7,314.29</td>
                          </tr>
                          <tr className="table-success">
                            <th>
                              Rs.7314/- EEI for this level ID Account holder{" "}
                            </th>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        <strong>
                          <b>EEI NOTES:</b>
                        </strong>
                      </p>
                      <p>
                        1. <strong> Closing Period: EEI </strong> is calculated
                        on the Business done during the Calander month.
                      </p>
                      <p>
                        2. <strong> Payout Period: EEI</strong> is paid on the
                        15th day of every corresponding month of the closing
                        month.
                      </p>
                      <p>
                        3. EEI is entitled to each entity against each employee
                        PV performance and subject to conditions timely dues of
                        the employee will be released as per offer of
                        appointment.
                      </p>
                      <p>
                        4. The maximum threshold limit for EEI is last salary
                        drawn by the ASL Account holder is Salary + PF + ESI +
                        Bonus + emoluments + incentive etc. paid by the
                        employer.
                      </p>
                      <p>
                        5. For the entitlement of EEI against each employee,
                        Service charge of the ASL need to be released well in
                        time as per invoice by the entity.
                      </p>
                      <p>
                        6. EEI capping for an ASL Account of employee is maximum
                        pay and perks of that employee during the tenure.
                      </p>
                      <p>
                        7. Active Business is calculated after all cancellations
                        and refunds are deducted from the current payout period.
                      </p>
                      <p>
                        8. Company reserves the right to change/modify{" "}
                        <strong> ENTITY MEMBER FUND.</strong>
                      </p>
                      <h2 className="title mb-2">
                        PARA-17: EPI: Entity and Employee Protection Incentive
                        8% of TPP
                      </h2>
                      <p>
                        Abhhyam Secured Life (ASL) entitles its EMC to an Entity
                        and Employee Protection Incentive (EPI) equivalent to 8
                        percent of the total TPP (PV turnover of the month) in a
                        certain month. ASL will distribute this to its EMC up to
                        the 20th generation among all EMCs as per self PV
                        generated in a certain month who maintain and submit
                        their compliance every month before 20th of every month.
                        EPI is calculated as per the example calculation shown
                        in Table of para 17.
                      </p>
                      <p>
                        <strong>EPI NOTES:</strong>
                      </p>
                      <div className=" about__list-box-three">
                        <ul style={{ listStyle: "decimal" }}>
                          <li>
                            Closing Period: EPI is calculated on the Business
                            done during the Calendar month.
                          </li>
                          <li>
                            Payout Period: EPI is paid on the 15th day of every
                            corresponding month of the closing month.
                          </li>
                          <li>
                            EPI is entitled to each entity subject to conditions
                            that timely dues of the employee may be released as
                            per terms and conditions.
                          </li>
                          <li>
                            Entity is entitled for EPI and the threshold is 20%
                            of each ASL Account holder pay and perks amount and
                            will be released after producing the compliance
                            report as per Government norms.
                          </li>
                          <li>
                            Active Business is calculated after all
                            cancellations and refunds are deducted from the
                            current payout period.
                          </li>
                          <li>
                            Company reserves the right to change/modify EPI.
                          </li>
                        </ul>
                      </div>
                      <h2 className="title mb-2">
                        PARA-18: Abhhyam Secured Life Fund (SLF) 40% of TPP
                      </h2>
                      <p>
                        Abhhyam Secured Life (ASL) entitles to EMC to Abhhyam
                        Secure Life fund (SLF) for each employee pensionary and
                        other service benefits equivalent to 40 percent of the
                        total TPP (PV turnover of the month) in a certain month.
                        Abhhyam Secured Life fund will distribute to EMC for
                        allocation of 6 types of pensionary and service benefits
                        for employees up to the 20th generation as per PV
                        generated in a certain month against the transaction of
                        entity. The various pensionary benefits for employees
                        against Abhhyam Secured Life Fund (SLF) are mentioned
                        below.
                      </p>
                      <h2 className="title mb-2">
                        PARA-19: Secure Life Bound (SLB) 16% of TPP
                      </h2>
                      <p>
                        Abhhyam Secured Life fund entitles its EMC a Secure Life
                        Bond (SLB) fund for each employee, equivalent to 16% of
                        the total TPP (PV turnover of the month) in a certain
                        month. Secure Life Bond (SLB) fund will distribute to
                        each ASL Account of employee of EMC up to the 20th
                        generation as per PV generated on the basis of service
                        transaction of entity. Each employee will get Secure
                        Life Bond (SLB) at the time of maturity of the tenure of
                        minimum 96 months from the date of joining in the
                        entity. Entity Secure Life Bond Fund will be utilized
                        for the release of Pension Bond at the time of maturity
                        and calculated as per the following formula, however,
                        for sample calculation table mentioned in para 19.
                        Accumulated SLB fund will be utilized to Secure the Life
                        of the employee at the time of quitting the
                        scheme/service through employer, minimum 96 months of
                        service tenure is required to get the benefits under
                        this scheme. However, the proposed maximum tenure of the
                        scheme is 144 months. SLB will be calculated on the
                        following Pension Bond Calculation (PBC) formula:
                      </p>
                      <p>
                        <strong>
                          (SLB Pension Bond Calculation (PBC) formula = = Last
                          Salary Drawn x total service years / 60 x 5)
                        </strong>
                      </p>
                      <p>
                        Abhhyam Secured Life Pension Bond will not be higher
                        than last pay drawn and maximum capping of Pension Bond
                        is Rs.50,000/- pm entitled only. The pension bond will
                        be provided at the time quitting the scheme from any
                        national insurance company, on the basis of the
                        accumulated pension bond fund under SLB account of ASL
                        Account.
                      </p>
                      <div className=" about__list-box-three">
                        <p>
                          <strong>SLB Note:</strong>
                        </p>
                        <ul style={{ listStyle: "lower-alpha" }}>
                          <li>
                            SLB is entitled after completion of 24-month service
                            of any ASL Account holder.
                          </li>
                          <li>
                            Pension is entitled after completion of minimum 96
                            months services.
                          </li>
                          <li>
                            Maximum 144 months service is allowed for ASL
                            Account Holder.
                          </li>
                          <li>
                            In case of change of the entity due to any reasons
                            and with due acceptance of the Employer, the
                            benefits of same ASL Account will be allowed to
                            continue in new Entity if ASL Scheme is available.
                          </li>
                        </ul>
                      </div>
                      <h2 className="title mb-2">
                        PARA-20: Abhhyam Secured Life Gratuity Fund (SGF) 4.80%
                        of TTP
                      </h2>
                      <p>
                        Abhhyam Secured Life fund entitles Employee of EMC a
                        Secure Gratuity Fund (SGF) equivalent to 4.80% percent
                        of the total TPP (PV turnover of the month) in a certain
                        month. Abhhyam Secured Life Gratuity Fund (SGF) will
                        distribute to each ASL Account of employee of EMC up to
                        the 20th generation as per PV generated of Entity
                        against monthly billing of third party pay rolls
                        services. Employee is entitled for Secure Life Gratuity
                        Fund (SGF) at the time of maturity of the tenure of
                        minimum 96 months and maximum at the time of retirement
                        of 144 months from the date of joining. Secure Life
                        Gratuity Fund (SGF) is calculated as per the
                        below-mentioned formula:
                      </p>
                      <p>
                        <strong>
                          SGF calculation and Capping formula = = (Number of
                          years of service x Last month Salary *12)/5
                        </strong>
                      </p>

                      <div className=" about__list-box-three">
                        <p>
                          <strong>SGF Note:</strong>
                        </p>
                        <ul style={{ listStyle: "lower-alpha" }}>
                          <li>
                            Minimum 36 months services are required for SGF.
                          </li>
                          <li>
                            If employee quit the service before 36 months of
                            service, SGF will not be entitled, However, after 36
                            months service and before maturity (retirement) if
                            employee quite the services, then SGF as entitled
                            will be released at the time of maturity only.
                          </li>
                        </ul>
                      </div>
                      <h2 className="title mb-2">
                        PARA-21: Leave Due Bonus (LDB) 3.20% of TPP
                      </h2>
                      <p>
                        Abhhyam Secured Life entitles Employees of EMC for Leave
                        Due Bonus (LDB) equivalent to 3.20% of the total TPP (PV
                        turnover of the month) in a certain month up to the 20th
                        generation. Abhhyam Secured Life (ASL) will distribute
                        Leave Due Bonus at the time of retirement of employee
                        against the leave not availed during the service.
                        Employees is entitled for Leave Due Bonus of one day
                        leave against 20 days duty. Employee Leave Due Bonus is
                        calculated as per the below-mentioned formula:
                      </p>
                      <p>
                        <strong>
                          LDB Calculation = Number of days duty / 20 * Last
                          salary/30
                        </strong>
                      </p>

                      <div className=" about__list-box-three">
                        <p>
                          <strong>Note: LDB</strong>
                        </p>
                        <ul style={{ listStyle: "lower-alpha" }}>
                          <li>
                            Minimum 36 months services for LDB are required.
                          </li>
                          <li>
                            If employee quit the service before 36 months of
                            service, LDB will not be entitled, However, after 36
                            months service and before maturity (retirement) if
                            employee quite the services, then LDB as entitled
                            will be released at the time of maturity only.
                          </li>
                        </ul>
                      </div>
                      <h2 className="title mb-2">
                        PARA-22: Leave Due Bonus (LDB) 3.20% of TPP
                      </h2>
                      <p>
                        Family Welfare is very important for each employer as
                        well as for employee, therefore, it is proposed that on
                        completion of 24 months service under this scheme,
                        employee will be eligible for Leave Travel Fund (LTF).
                        He can travel with his family (wife and 2 Children are
                        covered) through any mode of transport. However, maximum
                        two- month salary is authorized as Leave Travel Fund
                        (LTF). Employee member will be entitled for LTF for once
                        in a year. Therefore, Abhhyam Secured Life (ASL)
                        entitles employees of EMC a Leave Travel Fund (LTF)
                        equivalent to 3.80% of the total TPP (PV turnover of the
                        month) in a certain month. LTF is entitled for the
                        employees of EMC up to the 20th generation among as per
                        PV generated in a certain month. LTF is calculated as
                        per the sample table shown in the para 17.
                      </p>

                      <div className=" about__list-box-three">
                        <p>
                          <strong>Note: LDB</strong>
                        </p>
                        <ul style={{ listStyle: "lower-alpha" }}>
                          <li>
                            Entitled after completion of 24-month service after
                            ASL account activation.
                          </li>
                          <li>
                            Maximum 2-month Salary is allowed to avail for his
                            family travel expenses for anywhere in the
                            India/world.
                          </li>
                          <li>
                            At the time of retirement 2-month salary will be for
                            each LTF not availed year.
                          </li>
                        </ul>
                      </div>
                      <h2 className="title mb-2">
                        PARA-23: WES: Ward Education Scholarship 3.2% of TPP
                      </h2>
                      <p>
                        Education for all is basic need of citizens, hence, it
                        is also decided that after completion of 24 months
                        service by the employee member under this scheme, the
                        school and college going children of the members will be
                        eligible for Rs.3000/- pm WES from the scheme. Abhhyam
                        Secure Life (ASL) entitles employees of EMC a Ward
                        Education Scholarship (WES) equivalent to 3.20% of the
                        total TPP (PV turnover of the month) in a certain month
                        up to the 20th generation. Abhhyam Secured Life WES will
                        distribute for the higher education of their wards of
                        all ASL Account Holder after 24 months from his date of
                        joining. WES will be released on yearly basis. Each ASL
                        Account holder employee is entitled for Rs.3000/- per
                        month per child for his school, college or for any kind
                        of professional education. Ward Education Scholarship
                        (WES) funds 3.20% of TPP will be calculated as sample
                        table given in para 17 of the scheme.
                      </p>

                      <div className=" about__list-box-three">
                        <p>
                          <strong>WES Note:</strong>
                        </p>
                        <ul style={{ listStyle: "lower-alpha" }}>
                          <li>
                            Entitled after completion of 24-month service after
                            ASL account activation.
                          </li>
                          <li>
                            Maximum Rs.3000/- pm is allowed for two children
                            education for any level and standard.
                          </li>
                          <li>
                            At the time of retirement total amount of WES will
                            be released if not availed during the scheme tenure.
                          </li>
                        </ul>
                      </div>
                      <h2 className="title mb-2">
                        PARA-24: FSB: Family Welfare Bound Fund 9.60% of TPP
                      </h2>
                      <p>
                        Abhhyam Secured Life (ASL) entitles employees of EMC a
                        Family Secure Bond (FSB) equivalent to 9.60% of the
                        total TPP (PV turnover of the month) in a certain month
                        up to the 20th generation. This fund is created to
                        support and help the uncertain life of employees. In
                        case of death during the service of any ASL Account
                        Holder, a Family Secure Life Bond will be released for
                        the next-of-kin. On the basis of FSB Fund plus SLB fund
                        a pension bond will be released for the NOK of ASL
                        Account holder and all other dues and funds available
                        with ASL will also release to NOK.
                      </p>

                      <div className=" about__list-box-three">
                        <p>
                          <strong>FSB Note:</strong>
                        </p>
                        <ul style={{ listStyle: "lower-alpha" }}>
                          <li>
                            The family of each ASL Account Holder is entitled
                            FSB, after completion of 24-month service.
                          </li>
                          <li>
                            This fund will be utilized in case of death of any
                            ASL Account Holder only.
                          </li>
                          <li>
                            An annuity plan will be released as per the
                            availability of the FSB fund and discretion of the
                            management for the NOK of the ASL Account holder.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secured Life </p>
                          <a
                            href={
                              "./assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            <i className="far fa-file-pdf"></i>PDF Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default Incentives;
