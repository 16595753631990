import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import About from "../views/About";
import Founder from "../views/Founder";
import Certification from "../views/Certification";
import Initiative from "../views/Initiative";
import DirectMarketingPlan from "../views/DirectMarketingPlan";
import Advantages from "../views/Advantages";
import Incentives from "../views/Incentives";
import Downloads from "../views/Downloads";
import Contact from "../views/Contact";
import EmcRegistration from "../views/EmcRegistration";
import Members from "../views/Members";
import General from "../views/General";
import ErmRegistration from "../views/ErmRegistration";
import NotFound from "../views/NotFound";
import Disclaimer from "../views/Disclaimer";
import Privacy from "../views/Privacy";
import TermsUse from "../views/TermsUse";
import TermsAndConditions from "../views/TermsAndConditions";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/founder" element={<Founder />} />
      <Route path="/certification" element={<Certification />} />
      <Route path="/initiative" element={<Initiative />} />
      <Route path="/direct_marketing_plan" element={<DirectMarketingPlan />} />
      <Route path="/advantages" element={<Advantages />} />
      <Route path="/incentives" element={<Incentives />} />
      <Route path="/downloads" element={<Downloads />} />
      <Route path="/members" element={<Members />} />
      <Route path="/general" element={<General />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/emc-registration" element={<EmcRegistration />} />
      <Route path="/erm-registration" element={<ErmRegistration />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms-of-use" element={<TermsUse />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PageRoutes;
