import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function DirectMarketingPlan() {
  return (
    <WebLayout>
      <>
        <Link
          to="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html"
        >
          <i className="fas fa-angle-up"></i>
        </Link>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Marketing Plan</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Marketing Plan
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src={"./assets/img/images/breadcrumb_shape01.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape02.png"}
                alt=""
                className="rightToLeft"
              />
              <img src={"./assets/img/images/breadcrumb_shape03.png"} alt="" />
              <img src={"./assets/img/images/breadcrumb_shape04.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape05.png"}
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section className="services__details-area">
            <div className="container">
              <div className="services__details-wrap">
                <div className="row">
                  <div className="col-70 order-0 order-lg-2">
                    <div className="services__details-top">
                      <div className="row gutter-24 align-items-center">
                        <h2 className="title">
                          {" "}
                          PARA-8: Direct Selling Marketing Plan{" "}
                        </h2>
                        <div className="col-49 mb-2">
                          <div className="services__details-thumb services__details-thumb-two">
                            <img
                              src={
                                "./assets/img/marketing_plan/marketing/1.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-51">
                          <div className="services__details-top-content">
                            <p>
                              A direct selling marketing plan outlines
                              strategies and tactics for promoting and selling
                              products or services. It includes product
                              analysis, target market identification, marketing
                              strategies DIRECT SELLING (online/offline), lead
                              generation, training/support, evaluation metrics,
                              budgeting, compliance, and implementation
                              timeline. The Abhhyam Secure Private Limited
                              (ASPL) marketing plan aims to maximize sales
                              incentives and pensionary benefits funds on each
                              HRM and third-party payrolls service purchase by
                              the Entity Member Company (EMC).
                            </p>
                            <p>
                              Direct selling leverage income refers to the
                              ability to generate huge income by leveraging
                              direct selling networks. Direct selling involves
                              selling products or services directly to
                              consumers.
                            </p>
                            <p>
                              However, Abhhyam Secure Life (ASL) network
                              leverage allows for exponential income growth as
                              the network expands, providing the opportunity to
                              earn a significant and potentially income.
                            </p>
                          </div>
                        </div>
                        <p>
                          Through this initiative, now, over a period of time it
                          will be possible for the services sector to offer and
                          earn a leverage advantage by getting incentives on
                          services and pensionary bond and other service
                          benefits for employees.
                        </p>
                        <p>
                          Network marketing, also known as multi-level marketing
                          (MLM), has experienced significant growth in recent
                          years in India. The rise of social media and online
                          platforms has provided a conducive environment for
                          network marketing businesses to expand their reach and
                          engage with a broader audience. Hence, Abhhyam Secure
                          Life (ASL) is planning to put the service industry
                          through this network channel to earn Point Volumes
                          (PV) on each and every service purchase, resulting in
                          a regular incentive for buyer and pensionary bond and
                          other service benefits generation for their employees.
                        </p>
                      </div>
                    </div>
                    <div className="services__details-top">
                      <div className="row gutter-24 align-items-center">
                        <h2 className="title">
                          {" "}
                          PARA-9: Direct selling and challenges and solutions :{" "}
                        </h2>

                        <div className="col-51">
                          <div className="services__details-top-content">
                            <p>
                              The Networking marketing or direct selling concept
                              is accepted by very limited populations due to the
                              various reasons. However, Abhhyam Secure Life
                              (ASL) came out with solutions for each challenge
                              of the concept. Presently, direct selling provides
                              a very limited offer of products in the services
                              industry, whereas, Abhhyam Secure Life (ASL) gives
                              an open choice to the employer to buy the HR and
                              Third-Party payroll services as per his choice
                              available on the portal. Moreover, price
                              challenges are also being faced by the services
                              sector.
                            </p>
                          </div>
                        </div>
                        <div className="col-49 mb-2">
                          <div className="services__details-thumb services__details-thumb-two">
                            <img
                              src={
                                "./assets/img/marketing_plan/marketing/2.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <p>
                          Most Important is that the Abhhyam Secure Life (ASL)
                          marketing plan with a minimum 96 months and maximum
                          144 months fixed tenure, so that every Entity Member
                          Company (EMC) may able to get the proposed pensionary
                          benefits for employees and incentives for self against
                          the purchase of HR and Third-party pay rolls services
                          from the Abhhyam Secure Life (ASL) marketing plan.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secured Life </p>
                          <a
                            href={
                              "./assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            <i className="far fa-file-pdf"></i>PDF Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default DirectMarketingPlan;
