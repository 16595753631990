import React from "react";
import Footer from "../include/Footer";
import Header from "../include/Header";

const WebLayout = ({children, pageName}) => {

    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
}

export default WebLayout
