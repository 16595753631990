import React from "react";
import RequestCall from "../components/Requestcall";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <WebLayout>
      <>
        <Link
          to="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html"
        >
          <i className="fas fa-angle-up"></i>
        </Link>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Privacy Policy</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Privacy Policy
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src={"./assets/img/images/breadcrumb_shape01.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape02.png"}
                alt=""
                className="rightToLeft"
              />
              <img src={"./assets/img/images/breadcrumb_shape03.png"} alt="" />
              <img src={"./assets/img/images/breadcrumb_shape04.png"} alt="" />
              <img
                src={"./assets/img/images/breadcrumb_shape05.png"}
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section className="about__area-four">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-12">
                  <div className="section-title mb-10">
                    <h4>Privacy Policy for www.AbhhyamSecure.com</h4>
                  </div>
                  <p>
                    At www.AbhhyamSecure.com ("Website"), operated by Abhhyam
                    Secure Private Limited ("we," "our," "us"), we are committed
                    to protecting your privacy and safeguarding your personal
                    information. This Privacy Policy outlines how we collect,
                    use, disclose, and manage your information.
                  </p>
                  <ul className="listStyleDecimal">
                    <li>
                      <div className="section-title">
                        <h5>Information We Collect</h5>
                      </div>
                      <div className="about__list-box">
                        <p>
                          We may collect the following types of information when
                          you visit our Website or use our services:
                        </p>
                        <ul className="listStyleDisc">
                          <li>
                            <b>Personal Information:</b> Name, contact details
                            (email address, phone number, postal address), date
                            of birth, employee ID, and any other details you
                            provide during registration or service interaction.
                          </li>
                          <li>
                            <b>Account Information:</b> Entity Member Company
                            (EMC), Entity Member Account (EMA), and Entity
                            Representative Member (ERM) details, including ASL
                            ID accounts and associated Pension Points (PP).
                          </li>
                          <li>
                            <b>Payment Information:</b> Bank details,
                            transaction history, and other payment-related
                            information.
                          </li>
                          <li>
                            <b>Usage Information:</b> Browser type, IP address,
                            device type, operating system, and pages accessed on
                            the Website.
                          </li>
                          <li>
                            <b>Cookies and Tracking Data:</b> Information
                            collected through cookies and similar technologies
                            to enhance user experience.
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="section-title mt-1">
                        <h5>How We Use Your Information</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        <p className={"mb-0"}>We use your information to:</p>
                        <ul className="listStyleDisc mb-0">
                          <li>
                            Provide and manage the services under the Abhhyam
                            Secured Life (ASL) plan.
                          </li>
                          <li>
                            Process registrations, payments, and service
                            transactions.
                          </li>
                          <li>
                            Communicate important updates, promotional offers,
                            or changes to policies and services.
                          </li>
                          <li>
                            Improve the Website’s functionality and user
                            experience.
                          </li>
                          <li>
                            Comply with legal obligations and resolve disputes.
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="section-title">
                        <h5>Sharing Your Information</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        <p className={"mb-0"}>
                          Your information may be shared with:
                        </p>
                        <ul className="listStyleDisc mb-0">
                          <li>
                            <b>Service Providers:</b> Third-party entities
                            (e.g., insurance companies) involved in delivering
                            ASL benefits.
                          </li>
                          <li>
                            <b>Legal Authorities:</b> When required by law or to
                            protect the rights and safety of users and the
                            company.
                          </li>
                          <li>
                            <b>Affiliates and Partners:</b> For operational and
                            marketing purposes, with prior consent where
                            applicable.
                          </li>
                        </ul>
                        <p>
                          We do not sell or rent your personal information to
                          third parties.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="section-title">
                        <h5>Data Security</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        We employ industry-standard security measures to protect
                        your information from unauthorized access, loss, or
                        misuse. However, no method of transmission over the
                        Internet or electronic storage is 100% secure, and we
                        cannot guarantee absolute security.
                      </div>
                    </li>
                    <li>
                      <div className="section-title">
                        <h5>Your Rights</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        <p className={"mb-0"}>You have the right to:</p>
                        <ul className="listStyleDisc mb-0">
                          <li>
                            Access, correct, or update your personal
                            information.
                          </li>
                          <li>
                            Request deletion of your personal data, subject to
                            legal and contractual obligations.
                          </li>
                          <li>
                            Opt out of receiving promotional communications by
                            following the unsubscribe instructions in our
                            emails.
                          </li>
                        </ul>
                        <p>
                          To exercise your rights, contact us at
                          care@abhhyamsecure.com.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="section-title">
                        <h5>Cookies and Tracking Technologies</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        <ul className="listStyleDisc">
                          <li>
                            <b>Cookies:</b> We use cookies to enhance your
                            browsing experience, remember your preferences, and
                            analyze Website traffic.
                          </li>
                          <li>
                            <b>Your Choices:</b> You can manage or disable
                            cookies through your browser settings. Disabling
                            cookies may affect certain functionalities of the
                            Website.
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div className="section-title">
                        <h5>Third-Party Links</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        Our Website may contain links to third-party websites.
                        We are not responsible for the privacy practices or
                        content of these external sites. We encourage you to
                        review their privacy policies.
                      </div>
                    </li>
                    <li>
                      <div className="section-title">
                        <h5>Children’s Privacy</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        Our services are not intended for individuals under the
                        age of 18. We do not knowingly collect personal
                        information from children. If we become aware of such
                        data collection, we will take steps to delete it.
                      </div>
                    </li>
                    <li>
                      <div className="section-title">
                        <h5>Changes to This Privacy Policy</h5>
                      </div>
                      <div className="about__list-box mb-2">
                        We reserve the right to update this Privacy Policy at
                        any time. Changes will be posted on this page with the
                        updated effective date. We encourage users to review
                        this policy periodically.
                      </div>
                    </li>

                    <li>
                      <div className="section-title">
                        <h5>Contact Us</h5>
                      </div>
                      <div className="about__list-box">
                        <p>
                          If you have any questions or concerns about this
                          Privacy Policy or our data practices, please contact
                          us:
                        </p>
                        <ul className="listStyleDisc">
                          <li>
                            <b>Email:</b> care@abhhyamsecure.com
                          </li>
                          <li>
                            <b>Phone:</b> +91-9971 713 967
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <b>Effective Date: [Insert Date]</b>
                  <p>
                    Thank you for trusting Abhhyam Secure Private Limited with
                    your information.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </WebLayout>
  );
}

export default PrivacyPolicy;
