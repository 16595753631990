import React from "react";
import "../modal.css";

const MessageModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-header">
          <div className="success-icon">
            <i className="fa fa-check fa-2x" />
          </div>
          <h2 className="modal-title">Success</h2>
        </div>
        <div className="modal-body">
          <p className={"mb-0"}>{message}</p>
        </div>
        <div className="modal-footer">
          <button className="continue-btn" onClick={onClose}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
