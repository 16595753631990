import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footer-area">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="footer-widget">
                  <div className="fw-logo mb-25">
                    <Link to="#">
                      <img src={"./assets/img/logo/logo.png"} alt="Logo" />
                    </Link>
                  </div>
                  <div className="footer-content">
                    <p>
                      ASL (Abhhyam Secured Life) is an innovative and holistic
                      approach to employee welfare and organizational stability.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Information</h4>
                  <div className="footer-info-list">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <Link to="tel:9971713967">+91-9971713967</Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-envelope"></i>
                        </div>
                        <div className="content">
                          <Link to="mailto:care@abhhyamsecure.com">
                            care@abhhyamsecure.com
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Top Links</h4>
                  <div className="footer-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about">About ASL</Link>
                      </li>
                      <li>
                        <Link to="/disclaimer">Disclaimer</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-of-use">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions">
                          Terms and conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text text-center">
                  <p>
                    Copyright ©{" "}
                    <Link to="https://abhhyamsecure.com">
                      Abhhyamsecure.com
                    </Link>{" "}
                    | All Right Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img
            src="assets/img/images/footer_shape01.png"
            alt=""
            data-aos="fade-right"
            data-aos-delay="400"
          />
          <img
            src="assets/img/images/footer_shape02.png"
            alt=""
            data-aos="fade-left"
            data-aos-delay="400"
          />
          <img
            src="assets/img/images/footer_shape03.png"
            alt=""
            data-parallax='{"x" : 100 , "y" : -100 }'
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
