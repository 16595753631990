import React, { useEffect, useState, useCallback } from "react";
import WebLayout from "../layout/WebLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { BaseUrl } from "../Constant/BaseUrl";
import TermsAndModal from "../components/TermsAndModal";
import PrivacyModal from "../components/PrivacyModal";
import MessageModal from "../components/MessageModal";

function ErmRegistration() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    state: "",
    district: "",
    pinCode: "",
    address: "",
  });

  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [apiMessage, setApiMessage] = useState({
    type: "",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone" || name === "pinCode") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData((prevState) => ({ ...prevState, [name]: numericValue }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BaseUrl}erm-registration`,
        JSON.stringify(formData),
        { headers: { "Content-Type": "application/json" } },
      );

      if (response.data.status) {
        openModal();
        resetForm();
      } else {
        displayApiMessage("error_message", response.data.msg);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.msg ||
        "An error occurred while submitting the form.";
      displayApiMessage("error_message", errorMessage);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      password: "",
      state: "",
      district: "",
      pinCode: "",
      address: "",
    });
    setDistrictData([]);
  };

  const displayApiMessage = (type, message) => {
    setApiMessage({ type, message });
    setTimeout(() => setApiMessage({ type: "", message: "" }), 2000);
  };

  const fetchStateData = useCallback(async () => {
    try {
      const response = await axios.get(`${BaseUrl}state`);
      setStateData(response.data.data || []);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch states.");
    }
  }, []);

  const fetchDistrictData = useCallback(async (state_id) => {
    if (!state_id) return setDistrictData([]);
    try {
      const response = await axios.post(
        `${BaseUrl}district`,
        JSON.stringify({ state_id }),
        { headers: { "Content-Type": "application/json" } },
      );
      setDistrictData(response.data.data || []);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch districts.");
    }
  }, []);

  useEffect(() => {
    fetchStateData();
  }, [fetchStateData]);

  useEffect(() => {
    fetchDistrictData(formData.state);
  }, [formData.state, fetchDistrictData]);

  return (
    <WebLayout>
      <main id="top_bottom">
        <section
          className="breadcrumb__area breadcrumb__bg"
          style={{ padding: 20 }}
        >
          <div className="container">
            <div className="row">
              <TermsAndModal
                termsModal={termsModal}
                setTermsModal={setTermsModal}
              />
              <PrivacyModal
                privacyModal={privacyModal}
                setPrivacyModal={setPrivacyModal}
              />
              <div
                className={"col-lg-12 mb-3"}
                style={{ background: "#f7a400" }}
              >
                <h1
                  className="title text-center m-0"
                  style={{ fontStyle: "italic", fontSize: 35 }}
                >
                  ENTITY REPRESENTATIVE MEMBER (ERM)
                </h1>
              </div>
              <div className="col-lg-7">
                <p style={{ fontSize: 20 }}>
                  <span
                    style={{
                      color: "#f7a400",
                      fontWeight: 800,
                    }}
                  >
                    All individual sales and marketing leaders or marketing
                    company want to represent the Abhhyam Secured Life can
                    register as Abhhyam Secured Life Entity Representative
                    Member (ERM). Only Registered ERM will be entitled for the
                    following benefits:
                  </span>
                </p>
                <ul style={{ listStyle: "decimal" }}>
                  <li>
                    <b>ERM's Pension Points (PP):</b> Entity Representative
                    Members (ERM) earn 2% PP for each ASL ID account allotted by
                    an Entity Member Company (EMC).
                  </li>
                  <li>
                    <b>Month Closing:</b> Total PP earned by the ERM is
                    converted into Representative Member Fund (RMF) at
                    month-end.
                  </li>
                  <li>
                    <b>Representative Member Bonus (RMB):</b> 50% of RMF is
                    released as RMB.
                  </li>
                  <li>
                    <b>Pension Bond Fund (PBF):</b> 50% of RMF is kept as PBF
                    (maximum Rs. 5 crore allowed over 144 months).
                  </li>
                  <li>
                    <b>ERM Account on Maturity:</b> After 144 months, ERM's
                    account is removed from ASL and a new ASL ID account is
                    activated if EMC remains active and availing services.
                  </li>
                  <li>
                    <b>No PP Capping for ERM:</b> No cap on PP for ERM ASL ID. A
                    new ID is created for every 200 PP.
                  </li>
                  <li>
                    <b>Maximum RMB Capping:</b> RMB is capped at Rs. 10
                    lakhs/month, and an equal amount is deposited in the PBF,
                    subject to a PBF cap of Rs. 5 crore over 144 months.
                  </li>
                  <li>
                    <b>Pension Bond Release:</b> Upon achieving maximum PBF, a
                    pension bond is released by a national insurance company.
                  </li>
                </ul>
              </div>
              <div className="col-lg-5">
                {apiMessage.message && (
                  <p className={`${apiMessage.type}`}>{apiMessage.message}</p>
                )}
                <div
                  className="contact__form-wrap"
                  style={{ margin: 0, background: "#f7a400" }}
                >
                  <form id="registrationForm" onSubmit={handleSubmit}>
                    <div className="row">
                      <InputField
                        name="name"
                        value={formData.name}
                        handleChange={handleInputChange}
                        placeholder="Enter Full Name"
                        required
                      />
                      <InputField
                        name="email"
                        value={formData.email}
                        handleChange={handleInputChange}
                        placeholder="Enter Email"
                        required
                        type={"email"}
                      />
                      <InputField
                        name="phone"
                        value={formData.phone}
                        handleChange={handleInputChange}
                        placeholder="Enter Phone Number"
                        required
                        type="tel"
                        maxLength={10}
                      />
                      <InputField
                        name="password"
                        value={formData.password}
                        handleChange={handleInputChange}
                        placeholder="Enter Password"
                        required
                        type="password"
                      />
                      <SelectField
                        name="state"
                        value={formData.state}
                        options={stateData}
                        handleChange={handleInputChange}
                        placeholder="Select State"
                        required
                      />
                      <SelectField
                        name="district"
                        value={formData.district}
                        options={districtData}
                        handleChange={handleInputChange}
                        placeholder="Select District"
                        required
                      />
                      <InputField
                        name="pinCode"
                        value={formData.pinCode}
                        handleChange={handleInputChange}
                        placeholder="Enter Pincode"
                        required
                        maxLength={6}
                      />
                      <InputField
                        name="address"
                        value={formData.address}
                        handleChange={handleInputChange}
                        placeholder="Enter Address"
                        required
                      />
                    </div>
                    <div className="form-grp checkbox-grp">
                      <input
                        type="checkbox"
                        id="termsCheckbox"
                        checked
                        readOnly
                      />
                      <label style={{ fontWeight: "bold" }}>
                        I accept{" "}
                        <Link
                          to={"#"}
                          onClick={() => setTermsModal(true)}
                          style={{ fontWeight: "bold", color: "#3913ff" }}
                        >
                          Terms & Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          to={"#"}
                          onClick={() => setPrivacyModal(true)}
                          style={{ fontWeight: "bold", color: "#3913ff" }}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                    <div className="submit-button text-center">
                      <button
                        type="submit"
                        className="btn-success"
                        style={{
                          background: "#fb4343",
                          width: "100%",
                          color: "#ffffff",
                          padding: 10,
                          borderRadius: 10,
                          borderWidth: 0,
                          fontWeight: "bold",
                        }}
                      >
                        REGISTER
                      </button>
                      <p className={"m-2"}>
                        <b>OR</b>
                      </p>
                      <Link
                        to={"https://erm.abhhyamsecure.com/"}
                        target={"_blank"}
                      >
                        <button
                          type="button"
                          className="btn-success"
                          style={{
                            background: "#5bbf69",
                            width: "100%",
                            color: "#ffffff",
                            padding: 10,
                            borderRadius: 10,
                            borderWidth: 0,
                            fontWeight: "bold",
                          }}
                        >
                          LOGIN
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <MessageModal
            isOpen={isModalOpen}
            onClose={closeModal}
            message={
              "Congratulations, your account has been successfully created."
            }
          />
        </section>
      </main>
    </WebLayout>
  );
}

function InputField({
  type,
  name,
  value,
  handleChange,
  placeholder,
  error,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle the password visibility
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <div className="col-md-12">
      <div className="form-grp" style={{ position: "relative" }}>
        <input
          type={
            type === "password" && !showPassword
              ? "password"
              : type === "email"
                ? "email"
                : "text"
          }
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
          style={{
            background: "#ffd788",
            border: error ? "2px solid red" : "",
            fontWeight: 500,
            paddingRight: "30px", // Space for the eye icon
          }}
          className={"textPlaceholder"}
        />
        {type === "password" && (
          <div
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            {showPassword ? (
              <i className={"fa fa-eye-slash"} />
            ) : (
              <i className={"fa fa-eye"} />
            )}
          </div>
        )}
        {error && <div className="text-danger">{error}</div>}
      </div>
    </div>
  );
}

function SelectField({ name, value, handleChange, options, placeholder }) {
  return (
    <div className="col-md-6">
      <div className="form-grp">
        <select
          name={name}
          value={value}
          onChange={handleChange}
          style={{
            background: "#ffd788",
          }}
        >
          <option value="">{placeholder}</option>
          {options.map((opt, i) => (
            <option key={i} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default ErmRegistration;
