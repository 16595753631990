import React from "react";
import "../scrollbar.css";

const TermsAndModal = ({ termsModal, setTermsModal }) => {
  return (
    <div
      className={`modal fade show`}
      style={{
        display: termsModal ? "block" : "none",
        overflow: "hidden",
      }}
    >
      <div className="modal-dialog modal-xl scrollbar" id="style-4">
        <div className="modal-content">
          <div
            className="modal-header justify-content-between"
            style={{
              display: "flex !important",
              padding: 20,
              flexDirection: "unset",
            }}
          >
            <h4>Terms and Conditions for www.AbhhyamSecure.com</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setTermsModal(false)}
            />
          </div>
          <div>
            <div className="row p-4">
              <div className="col-lg-12">
                <p>
                  Welcome to www.AbhhyamSecure.com ("Website"), owned and
                  operated by Abhhyam Secure Private Limited ("we," "our,"
                  "us"). These Terms and Conditions ("Terms") govern your use of
                  our Website and services, including the Abhhyam Secured Life
                  (ASL) program. By accessing or using our Website, you agree to
                  abide by these Terms. If you do not agree, please refrain from
                  using the Website.
                </p>
                <ul className="listStyleDecimal">
                  <li>
                    <div className="section-title">
                      <h5>Definitions</h5>
                    </div>
                    <div className="about__list-box">
                      <ul className="listStyleDisc">
                        <li>
                          <b>Entity Member Company (EMC):</b> A legal
                          organization registered under the ASL scheme to avail
                          HR and payroll services.
                        </li>
                        <li>
                          <b>Entity Member Account (EMA):</b> An account created
                          for employees under the ASL scheme.
                        </li>
                        <li>
                          <b>Entity Representative Member (ERM):</b> Individuals
                          or entities promoting ASL services and eligible for
                          associated benefits.
                        </li>
                        <li>
                          <b>Pension Points (PP):</b> Points accrued through the
                          ASL plan to calculate employee and employer benefits.
                        </li>
                        <li>
                          <b>Secure Life Fund (SLF):</b> Funds allocated for
                          pensionary and other employee benefits.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title mt-1">
                      <h5>Eligibility</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc mb-0">
                        <li>
                          Users must be at least 18 years old to register or
                          participate in the ASL scheme.
                        </li>
                        <li>
                          EMCs must be legally registered entities to avail ASL
                          services.
                        </li>
                        <li>
                          Employees and representatives must comply with KYC
                          (Know Your Customer) requirements during registration.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Registration and Access</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <p className={"mb-0"}>
                        Your information may be shared with:
                      </p>
                      <ul className="listStyleDisc mb-0">
                        <li>
                          <b>For EMCs:</b> Registration is free. EMCs must
                          declare the total number of employees to allocate ASL
                          ID accounts proportionally.
                        </li>
                        <li>
                          <b>For Employees:</b> EMAs are created by EMCs for
                          their employees, enabling them to benefit from the ASL
                          scheme.
                        </li>
                        <li>
                          <b>For ERMs:</b> ERMs must register through the
                          Website and actively promote ASL services to qualify
                          for incentives.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>User Responsibilities</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          <b>Account Security:</b> Users are responsible for
                          maintaining the confidentiality of their account
                          credentials.
                        </li>
                        <li>
                          <b>Compliance:</b> Users must comply with all
                          applicable laws and the ASL scheme’s policies.
                        </li>
                        <li>
                          <b>Prohibited Activities:</b> Users must not:
                          <ul className="listStyleCircle">
                            <li>
                              Attempt unauthorized access to the Website or its
                              systems.
                            </li>
                            <li>
                              Use the Website for illegal purposes or transmit
                              harmful content.
                            </li>
                            <li>
                              Misrepresent information during registration or
                              use of services.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Services and Benefits</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc mb-0">
                        <li>
                          <b>Pensionary and Other Benefits:</b>
                          <ul className="listStyleCircle">
                            <li>
                              Employees accrue benefits based on their service
                              tenure and the PP earned.
                            </li>
                            <li>
                              EMCs and ERMs are entitled to incentives such as
                              Entity Employee Incentive (EEI), Entity Protection
                              Incentive (EPI), and Rep Member Bonus (RMB).
                            </li>
                            <li>
                              The minimum tenure for most benefits is 120
                              months, with a maximum of 144 months.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Incentives:</b>
                          <ul className="listStyleCircle">
                            <li>
                              Calculated monthly and subject to compliance with
                              ASL policies.
                            </li>
                            <li>
                              Payouts are processed as per schedules defined in
                              the scheme.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Modifications to Services</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          We reserve the right to modify, suspend, or
                          discontinue any service, incentive, or feature of the
                          ASL scheme without prior notice.
                        </li>
                        <li>
                          Changes will be communicated via the Website or other
                          official channels.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Limitation of Liability</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          Abhhyam Secure Private Limited shall not be held
                          liable for:
                          <ul className="listStyleCircle">
                            <li>
                              Any direct, indirect, or consequential damages
                              arising from the use of the Website or ASL scheme.
                            </li>
                            <li>
                              Inaccuracies or delays in benefit calculations or
                              payouts.
                            </li>
                          </ul>
                        </li>
                        <li>
                          The Website and services are provided "as is" without
                          warranties of any kind.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Data Protection and Privacy</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          Your use of the Website is governed by our Privacy
                          Policy, which outlines how we collect, use, and
                          protect your information.
                        </li>
                        <li>
                          Users consent to data sharing with third-party service
                          providers as required to deliver ASL benefits.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="section-title">
                      <h5>Termination of Services</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          We reserve the right to terminate or suspend any
                          user’s access to the Website or ASL scheme for:
                          <ul className="listStyleCircle">
                            <li>Violations of these Terms.</li>
                            <li>
                              Non-compliance with payment or other obligations
                              under the scheme.
                            </li>
                            <li>Fraudulent or unauthorized activities.</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div className="section-title">
                      <h5>Dispute Resolution</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          All disputes related to the Website or ASL scheme
                          shall be governed by the laws of India.
                        </li>
                        <li>
                          Exclusive jurisdiction for resolving disputes lies
                          with the courts of Delhi, India.
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div className="section-title">
                      <h5>Changes to Terms and Conditions</h5>
                    </div>
                    <div className="about__list-box mb-2">
                      <ul className="listStyleDisc">
                        <li>
                          We may update these Terms at any time without prior
                          notice. Updated Terms will be posted on the Website,
                          and continued use constitutes acceptance.
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <div className="section-title">
                      <h5>Contact Information</h5>
                    </div>
                    <div className="about__list-box">
                      <p>
                        For questions or concerns regarding these Terms, contact
                        us at:
                      </p>
                      <ul className="listStyleDisc">
                        <li>
                          <b>Email:</b> care@abhhyamsecure.com
                        </li>
                        <li>
                          <b>Phone:</b> +91-9971 713 967
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>

                <p>
                  By using www.AbhhyamSecure.com, you acknowledge that you have
                  read, understood, and agreed to these Terms and Conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsAndModal;
