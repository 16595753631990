import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";

const MobileMenuComponent = ({ isMenuOpen, setIsMenuOpen }) => {
  const popupRef = useRef(null);
  const [openMenus, setOpenMenus] = useState({});

  const handleClickOutside = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    },
    [setIsMenuOpen],
  );

  const toggleMenu = (menuIndex) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menuIndex]: !prev[menuIndex],
    }));
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      const backdrop = document.querySelector(".backdropContainer");
      if (backdrop) {
        backdrop.classList.remove("tgmobile__menu-backdrop");
        document.body.classList.remove("mobile-menu-visible");
      }
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      const backdrop = document.querySelector(".backdropContainer");
      if (backdrop) {
        backdrop.classList.remove("tgmobile__menu-backdrop");
      }
    };
  }, [isMenuOpen, handleClickOutside]);

  const removeClass = () => {
    setIsMenuOpen(false);
    document.body.classList.remove("mobile-menu-visible");
    const backdrop = document.querySelector(".backdropContainer");
    if (backdrop) backdrop.classList.remove("tgmobile__menu-backdrop");
  };

  return (
    <div>
      {isMenuOpen && (
        <div ref={popupRef} className="tgmobile__menu">
          <nav className="tgmobile__menu-box">
            <div
              className="close-btn"
              onClick={() => {
                setIsMenuOpen(false);
                removeClass();
              }}
            >
              <i className="fas fa-times"></i>
            </div>
            <div className="nav-logo">
              <Link to="/">
                <img src={"./assets/img/logo/logo.png"} alt="Logo" />
              </Link>
            </div>
            <div className="tgmobile__menu-outer">
              <ul className="navigation">
                {[
                  { label: "Home", href: "/", subMenu: [] },
                  {
                    label: "About Company",
                    subMenu: [
                      { label: "About ASL", href: "/about" },
                      { label: "Founder Team", href: "/founder" },
                      { label: "Certification", href: "/certification" },
                    ],
                  },
                  {
                    label: "Marketing Plan",
                    subMenu: [
                      { label: "Initiative", href: "/initiative" },
                      {
                        label: "Marketing Plan",
                        href: "/direct_marketing_plan",
                      },
                      { label: "Advantages", href: "/advantages" },
                      { label: "Incentives", href: "/incentives" },
                      { label: "Members", href: "/members" },
                      { label: "General", href: "/general" },
                    ],
                  },
                  { label: "Download", href: "/downloads", subMenu: [] },
                  { label: "Contact Us", href: "/contact", subMenu: [] },
                ].map((menu, index) => (
                  <li key={index} className="menu-item-has-children">
                    <Link to={menu.href}>{menu.label}</Link>
                    {menu.subMenu.length > 0 && (
                      <div
                        className="dropdown-btn"
                        onClick={() => toggleMenu(index)}
                      >
                        <span
                          className={
                            openMenus[index] ? "fa fa-minus" : "plus-line"
                          }
                        ></span>
                      </div>
                    )}
                    <ul
                      className="sub-menu"
                      style={{
                        display: openMenus[index] ? "block" : "none",
                      }}
                    >
                      {menu?.subMenu.map((subMenuItem, subIndex) => (
                        <li key={subIndex}>
                          <Link to={subMenuItem.href}>{subMenuItem.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
              <ul className={"p-0"}>
                <li className="header-btn list-unstyled w-100 p-2">
                  <Link className="btn w-100" to="/erm-registration">
                    Representative Registration
                  </Link>
                </li>
                <li className="header-btn list-unstyled p-2">
                  <Link
                    className="btn w-100"
                    to="/emc-registration"
                    style={{ background: "var(--tg-color-dark-blue)" }}
                  >
                    Company Registration
                  </Link>
                </li>
              </ul>
            </div>
            <div className="tgmobile__menu-bottom">
              <div className="contact-info">
                <ul className="list-wrap">
                  <li>
                    <a href="mailto:care@abhhyamsecure.com">
                      care@abhhyamsecure.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+919971713967">+91-9971713967</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default MobileMenuComponent;
